import { useAuth } from '@/lib/hooks/use-auth';
import { captureEvent } from '@/lib/utils/posthog';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAppSelector } from '../state/hooks';

// Types and Interfaces
interface ClickTrackerOptions {
  captureElements?: boolean;
  excludeSelectors?: string[];
  slackWebhookUrl?: string;
  sampling?: number;
  enableForPublicRoutes?: boolean;
}

interface ElementInfo {
  tagName: string;
  className: string;
  id: string;
  text: string | undefined;
  href?: string;
  dataAttributes: Record<string, string>;
}

interface PageInfo {
  title: string;
  path: string;
}

interface SessionData {
  newSession: boolean;
  pageViews: number;
  previousPage: PageInfo | null;
  timeOnSite: string;
  timeOnPage: string;
}

// Helper Functions
const isElement = (target: EventTarget | null): target is Element => {
  return target instanceof Element;
};

const isAnchorElement = (element: Element): element is HTMLAnchorElement => {
  return element.tagName.toLowerCase() === 'a';
};

const formatPageTitle = (title: string): string => {
  return title.replace(' | RipeMetrics', '');
};

const formatDuration = (ms: number): string => {
  // Validate the input
  if (typeof ms !== 'number' || isNaN(ms) || ms < 0) {
    return '0s';
  }

  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    return `${hours}h ${minutes % 60}m`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s`;
  }
  return `${seconds}s`;
};

const storePage = (title: string, path: string): string => {
  const pageInfo: PageInfo = { title: formatPageTitle(title), path };
  return JSON.stringify(pageInfo);
};

const getStoredPage = (stored: string | null): PageInfo | null => {
  if (!stored) return null;
  try {
    return JSON.parse(stored) as PageInfo;
  } catch {
    return null;
  }
};

const useClickTracker = (options: ClickTrackerOptions = {}) => {
  const {
    captureElements = true,
    excludeSelectors = [],
    slackWebhookUrl,
    sampling = process.env.NODE_ENV === 'production' ? 0.1 : 1,
    enableForPublicRoutes = false
  } = options;

  const currentUser = useAppSelector((state) => state.auth.user);
  const { isAuthenticated } = useAuth();
  const router = useRouter();

  const isPublicRoute =
    router.pathname.startsWith('/authentication/') ||
    router.pathname === '/' ||
    router.pathname === '/404' ||
    router.pathname === '/401' ||
    router.pathname === '/maintenance' ||
    router.pathname === '/get-started' ||
    router.pathname.startsWith('/onboarding/');

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (
      (!isAuthenticated && !enableForPublicRoutes) ||
      (isPublicRoute && !enableForPublicRoutes)
    ) {
      return;
    }

    const shouldTrackEvent = () => Math.random() < sampling;

    const handleClick = async (event: MouseEvent) => {
      try {
        if (
          currentUser?.email?.toLowerCase().includes('ripemetrics') ||
          currentUser?.email?.toLowerCase().includes('sharklasers.com') ||
          currentUser?.email?.toLowerCase().includes('ripechoco') ||
          currentUser?.email?.toLowerCase().includes('niclastdev3@gmail.com')
        ) {
          return; // Skip tracking for ripemetrics and test users
        }
        if (!shouldTrackEvent()) return;

        const target = event.target;
        if (!target || !isElement(target)) return;

        // Get or set session start time with validation
        let sessionStartTime = sessionStorage.getItem('sessionStartTime');
        const currentTime = Date.now();

        if (!sessionStartTime || isNaN(Number(sessionStartTime))) {
          sessionStartTime = currentTime.toString();
          sessionStorage.setItem('sessionStartTime', sessionStartTime);
        }

        // Get or set page start time with validation
        const currentPath = window.location.pathname;
        let pageStartTime = sessionStorage.getItem(`pageStart_${currentPath}`);

        if (!pageStartTime || isNaN(Number(pageStartTime))) {
          pageStartTime = currentTime.toString();
          sessionStorage.setItem(`pageStart_${currentPath}`, pageStartTime);
        }

        // Calculate durations with validated times
        const sessionDuration = formatDuration(
          currentTime - Number(sessionStartTime)
        );
        const pageDuration = formatDuration(
          currentTime - Number(pageStartTime)
        );

        const currentPageInfo = getStoredPage(
          sessionStorage.getItem('currentPage')
        );

        const sessionData: SessionData = {
          newSession: false, // We've already handled initialization above
          pageViews: Number(sessionStorage.getItem('pageViews') || 0) + 1,
          previousPage: getStoredPage(sessionStorage.getItem('lastPage')),
          timeOnSite: sessionDuration,
          timeOnPage: pageDuration
        };

        // Update page tracking
        if (currentPageInfo && currentPageInfo.path !== currentPath) {
          sessionStorage.setItem(
            'lastPage',
            sessionStorage.getItem('currentPage') || ''
          );
          sessionStorage.setItem(
            'currentPage',
            storePage(document.title, currentPath)
          );
        }

        // Collect click info
        const clickInfo = {
          x: event.clientX,
          y: event.clientY,
          timestamp: new Date().toISOString(),
          time: new Date().toLocaleString()
        };

        // Collect element info
        let elementInfo = {} as ElementInfo;
        if (captureElements) {
          elementInfo = {
            tagName: target.tagName.toLowerCase(),
            className: target.className,
            id: target.id,
            text: target.textContent?.slice(0, 100),
            href: isAnchorElement(target) ? target.href : undefined,
            dataAttributes: Array.from(target.attributes)
              .filter((attr) => attr.name.startsWith('data-'))
              .reduce(
                (acc, attr) => ({
                  ...acc,
                  [attr.name]: attr.value
                }),
                {} as Record<string, string>
              )
          };
        }

        const eventData = {
          authenticated: isAuthenticated,
          isPublicRoute,
          user: currentUser
            ? {
                id: currentUser.id,
                email: currentUser.email,
                name: currentUser.name,
                billing_tier: currentUser.billing?.servicePlan || 'unknown'
              }
            : undefined,
          page: {
            url: window.location.href,
            path: router.pathname,
            query: router.query,
            title: document.title
          },
          click: clickInfo,
          element: elementInfo,
          session: sessionData,
          device: {
            platform: navigator.platform,
            connection:
              (navigator as any).connection?.effectiveType || 'unknown',
            language: navigator.language,
            screen: `${window.screen.width}x${window.screen.height}`
          }
        };

        captureEvent('click_event', eventData);

        if (slackWebhookUrl) {
          const response = await axios.post(
            '/api/slack/analytics-slack-webhook',
            {
              blocks: [
                {
                  type: 'divider'
                },
                {
                  type: 'header',
                  text: {
                    type: 'plain_text',
                    text: '🎯 User Click Event',
                    emoji: true
                  }
                },
                {
                  type: 'section',
                  fields: [
                    {
                      type: 'mrkdwn',
                      text: `*User:*\n${
                        currentUser?.email || 'Anonymous'
                      }\n*Mobile:* ${currentUser?.mobile || 'N/A'}
                      \n*Plan:* ${currentUser?.billing?.servicePlan || 'N/A'}
                      \n*StoreID:* ${currentUser?.store_id || 'N/A'}
                      \n*OrganizationID:* ${
                        currentUser?.organization_id || 'N/A'
                      }

                      `
                    },
                    {
                      type: 'mrkdwn',
                      text: `*Session:*\nViews: ${sessionData.pageViews}\nTime: ${sessionData.timeOnSite}\n*Current Page:* ${sessionData.timeOnPage}`
                    }
                  ]
                },

                {
                  type: 'section',
                  fields: [
                    {
                      type: 'mrkdwn',
                      text: `*Current Page:*\n${formatPageTitle(
                        eventData.page.title
                      )}\n\`${eventData.page.path}\``
                    }
                  ]
                },
                {
                  type: 'section',
                  fields: [
                    {
                      type: 'mrkdwn',
                      text: `*Clicked Element:*\n${
                        elementInfo.text || elementInfo.tagName
                      }`
                    },
                    {
                      type: 'mrkdwn',
                      text: `*Element Type:*\n${elementInfo.tagName}${
                        elementInfo.className
                          ? ' (' + elementInfo.className + ')'
                          : ''
                      }`
                    }
                  ]
                },
                {
                  type: 'context',
                  elements: [
                    {
                      type: 'mrkdwn',
                      text: `💻 *Device:* ${eventData.device.platform} | 🔗 *Connection:* ${eventData.device.connection} | 🖥️ *Screen:* ${eventData.device.screen}`
                    }
                  ]
                },
                {
                  type: 'divider'
                }
              ].filter(Boolean),
              webhookUrl: slackWebhookUrl
            }
          );

          if (response.status !== 200) {
            throw new Error(`Slack API error: ${response.statusText}`);
          }
        }
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            location: window.location.href,
            user: currentUser?.email || 'Anonymous',
            timestamp: new Date().toISOString(),
            isPublicRoute
          }
        });
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [currentUser, isAuthenticated, router, options, isPublicRoute]);
};

export default useClickTracker;
