import { BellIcon } from '@/assets/icons/bell-icon';
import React, { FC } from 'react';
const NotificationPopoverEmptyState: FC = () => <div className="flex flex-col items-center justify-center px-4 py-8" data-sentry-component="NotificationPopoverEmptyState" data-sentry-source-file="notification-popover-empty-state.tsx">
    <div className="p-3 mb-4 bg-gray-100 rounded-full">
      <BellIcon className="w-6 h-6 text-gray-400" data-sentry-element="BellIcon" data-sentry-source-file="notification-popover-empty-state.tsx" />
    </div>
    <p className="text-sm text-center text-gray-500">No unread notifications</p>
    <p className="mt-1 text-xs text-center text-gray-400">
      All caught up! Check back later for new notifications.
    </p>
  </div>;
export default NotificationPopoverEmptyState;