import { Call, Device } from '@twilio/voice-sdk';
import { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/lib/state/hooks';
import {
  updateIsCallActive,
  updateOutboundCallStatus,
  setOutboundCall,
  updateIncomingCallStatus,
  subtractIvrCurrentCallsCounter,
  resetCallData
} from '@/lib/state/slices/call-center-slice';

/* Describe the useTwilioDevice hook */
// This hook is used to initialize the Twilio Device object and handle incoming and outgoing calls.
// It takes a token as an argument and returns the Twilio Device object.
// The token is used to authenticate the device with Twilio.
// The hook initializes the device and sets up event listeners for incoming and outgoing calls.
// It also handles call acceptance, disconnection, and errors.
// The hook returns the Twilio Device object, which can be used to make and receive calls.
// The hook also updates the Redux store with call status and incoming call data.

const useTwilioDevice = (token: string | null) => {
  const [device, setDevice] = useState<Device | null>(null);
  const deviceRef = useRef<Device | null>(null);
  const dispatch = useAppDispatch();
  const isOutboundCall = useAppSelector(
    (state) => state.callCenter.isOutboundCall
  );

  useEffect(() => {
    if (!token) return;

    // Cleanup previous device
    if (deviceRef.current) {
      deviceRef.current.destroy();
    }

    try {
      // console.log('Twilio Device Hook - Initializing Twilio Device');
      const deviceOptions: Device.Options = {
        logLevel: 'silent'
        // logLevel: 'debug'
        // logLevel: 'error'
        // Add any other necessary device options here
      };

      const newDevice = new Device(token, deviceOptions);

      const handleRegistered = () => {
        // console.log(
        //   'Twilio Device Hook - Twilio Device is ready to receive incoming calls.'
        // );
        dispatch(updateOutboundCallStatus('idle'));
      };

      const handleConnect = (conn: Call) => {
        // console.log(
        //   'Twilio Device Hook - device.on "connect" - Call connected'
        // );
        dispatch(updateOutboundCallStatus('connected'));
        dispatch(updateIsCallActive(true));
      };

      const handleIncoming = (call: Call) => {
        // console.log('Twilio Device Hook - PARAMS :', call.parameters);
        // console.log(
        //   'Twilio Device Hook - Incoming call from:',
        //   call.parameters.From
        // );

        const isOutbound =
          isOutboundCall ||
          call.parameters.Params?.includes('CallType=outbound');

        if (isOutbound) {
          // console.log('Twilio Device Hook - Outbound call has been answered.');
          dispatch(setOutboundCall(call.parameters.CallSid));
          dispatch(updateOutboundCallStatus('connected'));
          dispatch(updateIsCallActive(true));
        } else {
          // console.log('Twilio Device Hook - Handling new incoming call');
          dispatch(updateIsCallActive(true));
          dispatch(updateIncomingCallStatus(true));
        }

        call.on('accept', () => {
          // console.log(
          //   'Twilio Device Hook - call.on "accepted" - Call accepted'
          // );
          dispatch(subtractIvrCurrentCallsCounter());
          dispatch(updateOutboundCallStatus('connected'));
        });

        call.on('disconnect', () => {
          // console.log('Twilio Device - Call disconnected');
          dispatch(resetCallData());
        });

        call.on('error', (error) => {
          // console.error('Twilio Device - Call error:', error);
          dispatch(updateOutboundCallStatus('failed'));
        });
      };

      const handleError = (error: any) => {
        // console.error('Twilio Device error:', error);
        dispatch(updateOutboundCallStatus('failed'));

        if (error.code === 20101) {
          dispatch(resetCallData());
        }
      };

      // Attach event listeners
      newDevice.on('registered', handleRegistered);
      newDevice.on('connect', handleConnect);
      newDevice.on('incoming', handleIncoming);
      newDevice.on('disconnect', () => {
        // console.log('Call disconnected');
        dispatch(resetCallData());
      });
      newDevice.on('error', handleError);

      // Register device
      newDevice.register();

      // Update refs and state
      deviceRef.current = newDevice;
      setDevice(newDevice);

      // Cleanup function
      return () => {
        if (deviceRef.current) {
          deviceRef.current.destroy();
          deviceRef.current = null;
        }
      };
    } catch (error) {
      console.error('Error initializing Twilio device:', error);
      dispatch(updateOutboundCallStatus('failed'));
    }
  }, [token, dispatch, isOutboundCall]);

  return device;
};

export default useTwilioDevice;
