import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { MainSidebarContext } from '@/layouts/main/main-sidebar/main-sidebar-context';
import { MainSidebarComponent } from '@/layouts/main/main-sidebar/main-sidebar-component';
import { MainAppBar } from '@/layouts/main/main-app-bar/main-app-bar';
interface MainLayoutProps {
  children?: ReactNode;
}
const MainLayoutRoot = styled('div')(({
  theme
}) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  minHeight: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('xl')]: {
    paddingLeft: 280
  }
}));
export const MainLayout: FC<React.PropsWithChildren<MainLayoutProps>> = props => {
  const {
    children
  } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  return <>
      <MainSidebarContext.Provider value={{
      isSidebarOpen,
      setSidebarOpen: setIsSidebarOpen
    }} data-sentry-element="unknown" data-sentry-source-file="main-layout.tsx">
        <MainLayoutRoot data-sentry-element="MainLayoutRoot" data-sentry-source-file="main-layout.tsx">
          <Box sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          width: '100%',
          minHeight: '100%'
        }} data-sentry-element="Box" data-sentry-source-file="main-layout.tsx">
            <div className="h-full lg">{children}</div>
          </Box>
        </MainLayoutRoot>
        <MainAppBar onOpenSidebar={(): void => setIsSidebarOpen(true)} data-sentry-element="MainAppBar" data-sentry-source-file="main-layout.tsx" />
        <MainSidebarComponent onClose={(): void => setIsSidebarOpen(false)} open={isSidebarOpen} data-sentry-element="MainSidebarComponent" data-sentry-source-file="main-layout.tsx" />
      </MainSidebarContext.Provider>
    </>;
};
MainLayout.propTypes = {
  children: PropTypes.node
};