import { welcomeConfetti } from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-confetti';
import WelcomeModalAllContacts from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-all-contacts';
import WelcomeModalCalendarEvents from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-calendar-events';
import WelcomeModalCommunicationCrm from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-communication-center';
import WelcomeModalOptimizeAccount from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-optimize-account';
import WelcomeModalProspectManagement from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-prospect-management';
import WelcomeModalStartPage from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-start-page';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@/assets/icons/x-mark';
import WelcomeModalImagePreloader from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-image-preloader';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Dialog from '@mui/material/Dialog';
import clsx from 'clsx';
interface Props {
  closePopover?: () => void;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
}
const WelcomeModalComponent = ({
  openModal,
  setOpenModal,
  closePopover
}: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const router = useRouter();
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  useEffect(() => {
    if (openModal && activeStep === 0) {
      welcomeConfetti();
    }
  }, [openModal, activeStep]);
  const handleClose = () => {
    {
      router.replace({
        pathname: router.pathname,
        query: {}
      });
      setActiveStep(0);
      setOpenModal(false);
    }
  };
  return <>
      <WelcomeModalImagePreloader data-sentry-element="WelcomeModalImagePreloader" data-sentry-source-file="welcome-modal-component.tsx" />
      <React.Fragment>
        <Dialog fullScreen open={openModal} onClose={() => {
        router.replace({
          pathname: router.pathname,
          query: {}
        });
        setActiveStep(0);
        setOpenModal(false);
      }} data-sentry-element="Dialog" data-sentry-source-file="welcome-modal-component.tsx">
          <div className="fixed top-0 flex justify-end p-4 right-2">
            <div className="text-gray-400 cursor-pointer hover:text-orange-500" onClick={handleClose}>
              <XMarkIcon data-sentry-element="XMarkIcon" data-sentry-source-file="welcome-modal-component.tsx" />
            </div>
          </div>
          <div className="flex h-full w-full bg-[#f9fafb]">
            <div className="flex items-center">
              <div className="px-4">
                <button type="button" className={clsx('rounded-full p-2 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600', activeStep === 0 ? 'pointer-events-none bg-orange-100' : 'bg-orange-500 hover:bg-orange-600')} onClick={handleBack} disabled={activeStep === 0}>
                  <ChevronLeftIcon aria-hidden="true" className="w-10 h-10" data-sentry-element="ChevronLeftIcon" data-sentry-source-file="welcome-modal-component.tsx" />
                </button>
              </div>
            </div>
            <div className="z-10 flex w-full flex-col justify-center rounded-t-xl bg-[#f9fafb]">
              {activeStep === 0 && <WelcomeModalStartPage welcomeConfetti={welcomeConfetti} />}
              {activeStep === 1 && <WelcomeModalCommunicationCrm />}
              {activeStep === 2 && <WelcomeModalProspectManagement />}
              {activeStep === 3 && <WelcomeModalCalendarEvents />}
              {activeStep === 4 && <WelcomeModalAllContacts />}
              {activeStep === 5 && <WelcomeModalOptimizeAccount />}
            </div>
            <div className="flex items-center">
              {activeStep === 5 ? <>
                  <div className="px-4">
                    <button type="button" className="p-2 text-white bg-orange-500 rounded-full shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={() => {
                  router.replace({
                    pathname: '/optimize-account',
                    query: {}
                  });
                  closePopover?.();
                  setOpenModal(false);
                  setActiveStep(0);
                }}>
                      <ChevronRightIcon aria-hidden="true" className="w-10 h-10" />
                    </button>
                  </div>
                </> : <div className="px-4">
                  <button type="button" className="p-2 text-white bg-orange-500 rounded-full shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleNext} disabled={activeStep === 6}>
                    <ChevronRightIcon aria-hidden="true" className="w-10 h-10" />
                  </button>
                </div>}
            </div>
          </div>
        </Dialog>
      </React.Fragment>

      {openModal && activeStep === 0 && <canvas style={{
      zIndex: 10000,
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none'
    }} id="confetti-canvas" />}
    </>;
};
export default WelcomeModalComponent;