import posthog from 'posthog-js';
import type { NextRouter } from 'next/router';
import React from 'react';

export const initializePostHog = () => {
  // Check if PostHog is already initialized to prevent re-initialization
  if (typeof window !== 'undefined' && !posthog.get_distinct_id()) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
      // Disable session recording in development
      disable_session_recording: process.env.NODE_ENV === 'development',
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') {
          posthog.debug();
          console.log('PostHog initialized', {
            host: posthog.config.api_host,
            distinctId: posthog.get_distinct_id()
          });
        }
      }
    });
  }
  return posthog;
};

export const identifyUser = (
  userId: string | number,
  userProperties?: Record<string, any>
) => {
  // Convert to string and handle potential undefined
  const safeUserId = String(userId);

  if (typeof window !== 'undefined') {
    try {
      posthog.identify(safeUserId, userProperties);
    } catch (error) {
      console.error('PostHog identification failed', error);
    }
  }
};

export const captureEvent = (
  eventName: string,
  properties?: Record<string, any>
) => {
  if (typeof window !== 'undefined') {
    posthog.capture(eventName, properties);
  }
};

export const capturePageView = () => {
  if (typeof window !== 'undefined') {
    posthog.capture('$pageview');
  }
};

export const usePostHogPageView = (router: NextRouter) => {
  React.useEffect(() => {
    // Capture initial page view
    capturePageView();

    // Capture page views on route changes
    const handleRouteChange = () => capturePageView();
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
