import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
type Variant = 'light' | 'primary';
interface LogoProps {
  variant?: Variant;
  className?: string; // Add className to the interface
}

// base component that accepts className
const LogoBase = ({
  variant,
  className,
  ...other
}: LogoProps) => {
  return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="42" height="42" viewBox="0 0 42 42" xmlSpace="preserve" className={className} // Pass className to svg
  {...other} data-sentry-element="svg" data-sentry-component="LogoBase" data-sentry-source-file="icon-logo.tsx">
      <path fill="#09C675" d="M19.7,7.7c-0.2-0.1-0.4-0.1-0.5-0.3c-1-1.1-1.9-2.2-2.3-3.7c-0.3-1.2,0.3-2.1,1.5-2.1c0.6,0,1.2,0.2,2,0.3
	c-1,1.5-0.9,3-0.4,4.4C20.1,6.8,20.2,7.3,19.7,7.7z M34.5,2.7c-1.1-0.4-2.1-0.9-3.1-1.3c-0.9-0.4-1.8-0.8-2.8-1.1
	c-2.9-0.8-5.2,0.2-6.6,3c-0.5,0.9-0.7,2-1.1,3c-0.1,0.3-0.1,0.6-0.2,1.1c0.4-0.1,0.7-0.2,0.9-0.3c0.4-0.2,0.9-0.5,1.3-0.7
	c1.9-1.2,3.8-1.9,6-1.6c1.2,0.2,2.4,0.5,3.6,0.8c2.1,0.5,3.8-0.7,4.1-3C36,2.9,35.2,2.9,34.5,2.7z" data-sentry-element="path" data-sentry-source-file="icon-logo.tsx" />
      <linearGradient id="linear-gradient" x1="8.33" y1="35.6" x2="32.05" y2="15.91" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="icon-logo.tsx">
        <stop offset="0.06" stopColor="#e0284b" data-sentry-element="stop" data-sentry-source-file="icon-logo.tsx" />
        <stop offset="0.27" stopColor="#e75048" data-sentry-element="stop" data-sentry-source-file="icon-logo.tsx" />
        <stop offset="0.73" stopColor="#f9b43f" data-sentry-element="stop" data-sentry-source-file="icon-logo.tsx" />
        <stop offset="0.85" stopColor="#fece3d" data-sentry-element="stop" data-sentry-source-file="icon-logo.tsx" />
      </linearGradient>
      <path id="gradient" style={{
      fill: 'url(#linear-gradient)'
    }} d="M25.8,24.8l8.6-7.3c0.4,0.6,0.7,1.2,1,1.9L28.9,25h-3.2L25.8,24.8z M21,9.5v2.6l2.7-2.3
	C22.8,9.7,21.9,9.6,21,9.5z M34.6,25l1.9-1.6c-0.1-0.8-0.3-1.5-0.5-2.3L31.4,25H34.6z M29.3,12.2l-8.2,7v2.7l10-8.4
	C30.4,13,29.9,12.6,29.3,12.2z M25.6,10.4L21,14.3V17l6.7-5.7C27,11,26.3,10.7,25.6,10.4z M33.5,16.2c-0.4-0.6-0.9-1.1-1.3-1.6
	L21,24V25h2.1L33.5,16.2z M22.5,26.5h-2h-0.7h-0.4v-1.1v-2.1V9.5C10.7,10,3.8,17,3.8,25.7c0,0.3,0,0.5,0,0.8h0c0,0.2,0,0.4,0.1,0.6
	c0,0,0,0,0,0c0.7,7.7,6.8,13.9,14.6,14.7c0,0,0,0,0,0c0.6,0.1,1.1,0.1,1.7,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0
	c0.3,0,0.7,0,1,0c0.3,0,0.6-0.1,1-0.1c0.2,0,0.3,0,0.5-0.1c0.4-0.1,0.7-0.1,1.1-0.2c0.1,0,0.1,0,0.2-0.1c6.8-1.8,11.9-7.7,12.3-14.9
	H22.5z" data-sentry-element="path" data-sentry-source-file="icon-logo.tsx" />
    </svg>;
};

// Apply styled to the base component
export const IconLogo = styled(LogoBase)``;
IconLogo.defaultProps = {
  variant: 'primary'
};
IconLogo.propTypes = {
  variant: PropTypes.oneOf<Variant>(['light', 'primary'])
};