import {
  CalendarDay,
  CalendarEventsData,
  CalendarMonthData,
  CreateEventControls,
  OverlappingSingleDrawerEvent
} from '@/lib/types/calendar';
import { createSlice } from '@reduxjs/toolkit';

export interface CalendarSliceState {
  currentMonthData: CalendarMonthData | null;
  view: 'month' | 'week' | 'day';
  storeTimeZone: string | null;
  currentWeekData: CalendarDay[][] | null;
  calendarEvents: CalendarEventsData[];
  currentWeekIndex: number;
  currentDayIndex: number;
  isEventsDrawerOpen: boolean;
  createUpdateEventModalControls: CreateEventControls;
  singleEventShowDrawerControls: OverlappingSingleDrawerEvent;
  isMultipleEventsDrawerOpen: boolean;
  multipleEventsOneDay: CalendarEventsData[];
  multipleEventsPopoverAnchorEl: HTMLButtonElement | null;
  refetchCommunicationCrmCalendar: boolean;
}

const initialState: CalendarSliceState = {
  // views and timezone
  view: 'month',
  storeTimeZone: null,
  // calendar data
  currentMonthData: null,
  currentWeekData: null,
  calendarEvents: [],
  // data indexes
  currentWeekIndex: 0,
  currentDayIndex: 0,
  // drawers and modal controls
  isEventsDrawerOpen: true,
  createUpdateEventModalControls: {
    open: false,
    date: '',
    time: '',
    all_day: false,
    id: null,
    customer: {
      id: null,
      name: ''
    }
  },
  singleEventShowDrawerControls: {
    open: false,
    id: null
  },
  isMultipleEventsDrawerOpen: false,
  multipleEventsOneDay: [],
  multipleEventsPopoverAnchorEl: null,
  refetchCommunicationCrmCalendar: false
};

const CalendarSlice = createSlice({
  name: 'Calendar',
  initialState,
  reducers: {
    setCurrentMonthData: (state, action) => {
      state.currentMonthData = action.payload;
    },
    changeView: (state, action) => {
      state.view = action.payload;
    },
    setStoreTimeZone: (state, action) => {
      state.storeTimeZone = action.payload;
    },
    setCurrentWeekData: (state, action) => {
      state.currentWeekData = action.payload;
    },
    setCalendarEvents: (state, action) => {
      state.calendarEvents = action.payload;
    },
    changeCurrentWeekIndex: (state, action) => {
      state.currentWeekIndex = action.payload;
    },
    changeCurrentDayIndex: (state, action) => {
      state.currentDayIndex = action.payload;
    },
    toggleEventsDrawer: (state, action) => {
      state.isEventsDrawerOpen = action.payload;
    },
    updateEventsModalControls: (state, action) => {
      state.createUpdateEventModalControls = {
        ...state.createUpdateEventModalControls,
        ...action.payload
      };
    },
    resetCreateUpdateEventModalControls: (state) => {
      state.createUpdateEventModalControls =
        initialState.createUpdateEventModalControls;
    },
    updateShowEventDrawerControls: (state, action) => {
      state.singleEventShowDrawerControls = {
        ...state.singleEventShowDrawerControls,
        ...action.payload
      };
    },
    resetShowEventDrawerControls: (state) => {
      state.singleEventShowDrawerControls =
        initialState.singleEventShowDrawerControls;
    },
    toggleMultipleEventsDrawer: (state, action) => {
      state.isMultipleEventsDrawerOpen = action.payload;
    },
    setMultipleEventsOneDay: (state, action) => {
      state.multipleEventsOneDay = action.payload;
    },
    setMultipleEventsPopoverAnchorEl: (state, action) => {
      state.multipleEventsPopoverAnchorEl = action.payload;
    },
    refetchCommunicationCrmCalendar: (state, action) => {
      state.refetchCommunicationCrmCalendar = action.payload;
    }
  }
});

export const {
  setCurrentMonthData,
  changeView,
  setStoreTimeZone,
  setCurrentWeekData,
  setCalendarEvents,
  changeCurrentWeekIndex,
  changeCurrentDayIndex,
  toggleEventsDrawer,
  updateEventsModalControls,
  resetCreateUpdateEventModalControls,
  updateShowEventDrawerControls,
  resetShowEventDrawerControls,
  toggleMultipleEventsDrawer,
  setMultipleEventsOneDay,
  setMultipleEventsPopoverAnchorEl,
  refetchCommunicationCrmCalendar
} = CalendarSlice.actions;

export default CalendarSlice.reducer;
