import { TimeUnit } from '@/components/crm/scheduling-assistant/components/create-new-event-modal/set-custom-notification-time-modal';

export const formattedDate = (date: string) => {
  if (date === 'Not Yet Contacted') return date;
  const dateObj = new Date(date);
  const newDate = dateObj.toLocaleString('default', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
  return newDate;
};

// combines date in time into one date string to be sent to api
export const combineDateAndTimeHelperFunc = (
  time: Date,
  date: string
): Date => {
  const finalDate = time.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  });

  const endAtDateTime = new Date(date);
  const [hours, minutes] = finalDate.split(':');
  endAtDateTime.setHours(parseInt(hours), parseInt(minutes));
  return endAtDateTime;
};

// Conversion function: Converts the time value and unit to minutes
export const convertToMinutes = (value: string, unit: TimeUnit): number => {
  const numericValue = parseInt(value);
  switch (unit) {
    case 'Week':
      return numericValue * 10080; // 7 days * 24 hours * 60 minutes
    case 'Day':
      return numericValue * 1440; // 24 hours * 60 minutes
    case 'Hour':
      return numericValue * 60;
    case 'Minute':
      return numericValue;
    default:
      return 0;
  }
};

// Notification History Date Formatting
export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
};
