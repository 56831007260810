import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { logOut, setCredentials } from '../state/slices/auth-slice';
import { setTwilioToken, identity, updateCallCenterName } from '@/lib/state/slices/call-center-slice';
import { updateUserPermissions } from '@/lib/state/slices/user-permissions-slice';
import { persistor } from '@/lib/state/store';
import axios from 'axios';
import { UserInterface } from '@/lib/types/user-interface';
const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api`;
const sanctumBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
interface LoginResponse {
  token: string;
  user: UserInterface;
}
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: UserInterface | null;
}
interface AuthContextValue extends State {
  login: (loginFormData: {
    email: string;
    password: string;
    redirectPath?: string;
  }) => Promise<unknown>;
  logout: () => Promise<void>;
  isLoading: boolean;
}
const initialState: State = {
  isInitialized: false,
  isAuthenticated: false,
  user: null
};
type Action = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: UserInterface | null;
  };
} | {
  type: 'LOGIN';
  payload: {
    user: UserInterface;
  };
} | {
  type: 'LOGOUT';
};
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};
export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  isLoading: false
});
export const AuthProvider: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const dispatchRedux = useAppDispatch();
  const router = useRouter();
  const userInState = useAppSelector(state => state.auth.user);
  const clearAuthState = () => {
    window.localStorage.clear();
    sessionStorage.clear();

    // Clear cookies
    document.cookie.split(';').forEach(cookie => {
      const name = cookie.split('=')[0].trim();
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${window.location.hostname};`;
    });
    posthog.reset();
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        user: null
      }
    });
  };
  const initializeAuth = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const savedUser = window.localStorage.getItem('userData');
      if (!accessToken) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        return null;
      }

      // Set axios default authorization header
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      // Check if user exists in Redux state first
      if (userInState) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: userInState
          }
        });
        posthog.identify(String(userInState.id), {
          email: userInState.email,
          name: userInState.name
        });
        return userInState;
      }

      // Check if we have user data in localStorage
      if (savedUser) {
        const user = JSON.parse(savedUser) as UserInterface;
        dispatchRedux(setCredentials({
          user
        }));
        if (user.permissions) {
          dispatchRedux(setTwilioToken(user.permissions.token));
          dispatchRedux(identity(user.permissions.identity));
          dispatchRedux(updateUserPermissions(user.permissions));
        }
        if (user.call_center_name) {
          dispatchRedux(updateCallCenterName(user.call_center_name));
        }
        posthog.identify(String(user.id), {
          email: user.email,
          name: user.name
        });
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
        return user;
      }

      // If no saved user data, fetch from API
      const response = await axios.get<{
        user: UserInterface;
      }>(`${baseUrl}/user`);
      const userData = response.data;
      if (userData?.user) {
        const user = userData.user;

        // Save user data to localStorage
        window.localStorage.setItem('userData', JSON.stringify(user));
        dispatchRedux(setCredentials({
          user
        }));
        if (user.permissions) {
          dispatchRedux(setTwilioToken(user.permissions.token));
          dispatchRedux(identity(user.permissions.identity));
          dispatchRedux(updateUserPermissions(user.permissions));
        }
        if (user.call_center_name) {
          dispatchRedux(updateCallCenterName(user.call_center_name));
        }
        posthog.identify(String(user.id), {
          email: user.email,
          name: user.name
        });
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
        return user;
      }
      clearAuthState();
      return null;
    } catch (error) {
      console.error('Initialize auth error:', error);
      clearAuthState();
      return null;
    }
  };
  useEffect(() => {
    initializeAuth();
  }, []);
  const login = async (loginFormData: {
    email: string;
    password: string;
    redirectPath?: string;
  }): Promise<unknown> => {
    try {
      await axios.get(`${sanctumBaseUrl}/sanctum/csrf-cookie`);
      const loginResponse = await axios.post<LoginResponse>(`${baseUrl}/login`, {
        email: loginFormData.email,
        password: loginFormData.password
      });
      const {
        token,
        user
      } = loginResponse.data;
      if (!token) {
        clearAuthState();
        return {
          error: {
            status: 401,
            data: {
              message: 'No token received in login response'
            }
          }
        };
      }
      setIsLoading(true);

      // Save both token and user data to localStorage
      window.localStorage.setItem('accessToken', token);
      window.localStorage.setItem('userData', JSON.stringify(user));

      // Set axios default authorization header
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Update Redux state
      dispatchRedux(setCredentials({
        user
      }));
      if (user.permissions) {
        dispatchRedux(setTwilioToken(user.permissions.token));
        dispatchRedux(identity(user.permissions.identity));
        dispatchRedux(updateUserPermissions(user.permissions));
      }
      if (user?.call_center_name) {
        dispatchRedux(updateCallCenterName(user.call_center_name));
      }

      // Update auth context
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });

      // Identify user in PostHog
      posthog.identify(String(user.id), {
        email: user.email,
        name: user.name
      });
      const redirectUrl = loginFormData.redirectPath || '/crm/communication-crm';
      await router.push(redirectUrl);
      setIsLoading(false);
      return {};
    } catch (error) {
      console.error('Login failed:', error);
      clearAuthState();
      if (axios.isAxiosError(error) && error.response) {
        return {
          error: {
            status: error.response.status,
            data: error.response.data
          }
        };
      }
      return {
        error: {
          status: 500,
          data: {
            message: 'An unexpected error occurred'
          }
        }
      };
    }
  };
  const logout = async (): Promise<void> => {
    setIsLoading(true);
    try {
      // Update auth context state first
      dispatch({
        type: 'LOGOUT'
      });

      // Clear Redux state
      dispatchRedux(logOut());
      await persistor.purge();

      // Clear all stored data
      clearAuthState();

      // Cleanup Pusher/Echo
      if (window.Echo?.connector?.pusher) {
        try {
          window.Echo.connector.pusher.unsubscribe();
          window.Echo.connector.pusher.disconnect();
          window.Echo.connector.pusher = null;
          delete window.Echo;
        } catch (error) {
          console.warn('Error cleaning up Pusher:', error);
        }
      }

      // Make the logout request to the backend
      try {
        await axios.post(`${baseUrl}/logout`);
      } catch (error) {
        console.warn('Backend logout failed, but continuing with local logout', error);
      }

      // Clear axios default headers
      delete axios.defaults.headers.common['Authorization'];

      // Finally, redirect to login page
      setIsLoading(false);
      await router.push('/authentication/login');
    } catch (error) {
      console.error('Logout error:', error);
      // Even if there's an error, ensure we reset the state and redirect
      setIsLoading(false);
      router.push('/authentication/login').catch(console.error);
    }
  };
  return <AuthContext.Provider value={{
    ...state,
    login,
    logout,
    isLoading
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="auth-context.tsx">
      {children}
    </AuthContext.Provider>;
};
export const AuthConsumer = AuthContext.Consumer;