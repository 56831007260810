import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/hooks/use-auth';
import { SplashScreen } from '@/components/_shared/splash-screen';
import usePageType from '@/lib/hooks/use-page-type';
interface GuestGuardProps {
  children: ReactNode;
}

/**
 * GuestGuard Component
 *
 * This component acts as a protection layer for guest-only routes (like login, register, etc.).
 * It prevents authenticated users from accessing these routes by redirecting them to the dashboard,
 * except for onboarding pages which should be accessible regardless of authentication status.
 *
 * Usage:
 * ```tsx
 * <GuestGuard>
 *   <LoginPage />
 * </GuestGuard>
 * ```
 *
 * Flow:
 * 1. Component mounts and waits for router and auth to be ready
 * 2. Checks if user is authenticated and page type
 * 3. If authenticated and not onboarding, redirects to dashboard
 * 4. If not authenticated or onboarding, renders the children
 *
 * @param {GuestGuardProps} props - Component props
 * @returns {ReactNode | null} Returns SplashScreen during checks, children if access is allowed
 */
export const GuestGuard: FC<React.PropsWithChildren<GuestGuardProps>> = props => {
  const {
    children
  } = props;
  const {
    isAuthenticated,
    isInitialized
  } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);
  const pageType = usePageType();

  // Development-only flag to bypass guard
  const disableGuard = router.query.disableGuard as string | undefined;
  useEffect(() => {
    // Early return if router is not ready or auth is not initialized
    if (!router.isReady || !isInitialized) {
      return;
    }
    const handleAuthCheck = async () => {
      try {
        // Always allow access to onboarding pages
        if (pageType === 'onboarding') {
          setChecked(true);
          return;
        }

        // Handle non-onboarding pages
        if (isAuthenticated && disableGuard !== 'true') {
          // Use window.location for a hard redirect to ensure clean state
          window.location.href = '/crm/communication-crm';
          return;
        }
        setChecked(true);
      } catch (error) {
        console.error('Error during auth redirect:', error);
        setChecked(true);
      }
    };
    handleAuthCheck();
  }, [router.isReady, isAuthenticated, isInitialized, disableGuard, pageType]);

  // Show splash screen while checking auth or during redirect
  if (!checked || isAuthenticated && pageType !== 'onboarding') {
    return <SplashScreen />;
  }

  // Render children if:
  // 1. User is not authenticated
  // 2. Page is onboarding type
  // 3. Guard is disabled (development only)
  return <>{children}</>;
};
GuestGuard.propTypes = {
  children: PropTypes.node
};
export default GuestGuard;