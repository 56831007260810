import { SystemNotification } from '@/lib/state/services/notifications-api';
import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '@mui/material';
import React from 'react';
interface Props {
  handleMarkAllAsRead?: () => void;
  notifications: SystemNotification[];
}
const NotificationPopoverHeader = ({
  handleMarkAllAsRead,
  notifications
}: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return <div className="flex items-center justify-between p-4 text-center text-gray-700 border-b border-zinc-200" data-sentry-component="NotificationPopoverHeader" data-sentry-source-file="notification-popover-header.tsx">
      <h2 className="text-lg font-semibold">Notifications</h2>
      {notifications.length === 0 ?
    // Disabled State
    <EnvelopeIcon className="w-6 h-6 text-gray-300" /> : <Tooltip title="Mark all as read">
          <button onClick={handleMarkAllAsRead} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className="relative w-6 h-6 text-gray-500 transition-colors duration-200 hover:text-gray-700">
            <div className="absolute bottom-0 left-0">
              {isHovered ? <EnvelopeOpenIcon className="w-6 h-6 " /> : <EnvelopeIcon className="w-6 h-6 " />}
            </div>
          </button>
        </Tooltip>}
    </div>;
};
export default NotificationPopoverHeader;