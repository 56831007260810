import { TFunction } from 'react-i18next';
import { ReactNode } from 'react';
import { crmIcon, engageIcon, intelIcon } from '@/layouts/main/main-sidebar/main-sidebar-svg';
import { UserInterface } from '@/lib/types/user-interface';
export interface AppSidebarProps {
  onClose?: () => void;
  open?: boolean;
}
export interface Children {
  title: string;
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  is_visible?: boolean;
  is_active?: boolean;
  is_category?: boolean;
  ai_enhanced?: boolean;
}
export interface Item {
  title: string;
  children?: Children[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  is_visible?: boolean;
  is_active?: boolean;
}
export interface Section {
  title: string;
  items: Item[];
}
export const getSidebarSections = (t: TFunction, currentPath: string, user: UserInterface): Section[] => {
  const isActive = (basePath: string, isCustomerSegments?: boolean): boolean => {
    if (isCustomerSegments) {
      return currentPath.includes('customer-segments') || currentPath.includes('create-a-segment');
    }
    return currentPath.startsWith(basePath);
  };
  const permissions = user?.permissions;
  const isOwner = permissions?.owner;
  const items = [{
    title: t('CRM'),
    path: '/crm/communication-crm',
    is_visible: isOwner || permissions?.can_access_ripe_crm,
    is_active: isActive('/crm/'),
    icon: crmIcon,
    children: [{
      title: 'Communication CRM',
      path: '/crm/communication-crm',
      is_active: isActive('/crm/communication-crm'),
      is_visible: true,
      ai_enhanced: true
    }, {
      title: 'Contact Manager',
      path: '/crm/contact-manager',
      is_active: isActive('/crm/contact-manager'),
      is_visible: true
    }, {
      title: 'Sales Workflow',
      path: '/crm/sales-workflow',
      is_active: isActive('/crm/sales-workflow'),
      is_visible: true,
      ai_enhanced: true
    }, {
      title: 'Scheduling Assistant',
      path: '/crm/scheduling-assistant',
      is_active: isActive('/crm/scheduling-assistant'),
      is_visible: true
    }]
  }, {
    title: t('ENGAGE'),
    path: '/engage/',
    is_visible: isOwner || permissions?.can_access_ripe_engage,
    is_active: isActive('/engage/'),
    icon: engageIcon,
    children: [{
      title: 'Marketing Workflow',
      path: '/engage/marketing-workflow/customer-segments/automated-segments',
      is_active: false,
      is_visible: true,
      is_category: true
    }, {
      title: 'Customer Segments',
      path: '/engage/marketing-workflow/customer-segments/automated-segments',
      is_active: isActive('/engage/marketing-workflow/customer-segments', true),
      is_visible: true,
      ai_enhanced: true
    }, {
      title: 'Campaign Studio',
      path: '/engage/marketing-workflow/campaign-studio',
      is_active: isActive('/engage/marketing-workflow/campaign-studio'),
      is_visible: true,
      ai_enhanced: true
    }, {
      title: 'Landing Page Studio',
      path: '/engage/marketing-workflow/landing-page-studio',
      is_active: isActive('/engage/marketing-workflow/landing-page-studio'),
      is_visible: true,
      ai_enhanced: true
    }, {
      title: 'Growth & Loyalty',
      path: '/engage/growth-loyalty/product-booster',
      is_active: false,
      is_visible: true,
      is_category: true
    }, {
      title: 'Product Booster',
      path: '/engage/growth-loyalty/product-booster',
      is_active: isActive('/engage/growth-loyalty/product-booster'),
      is_visible: true,
      ai_enhanced: true
    }, {
      title: 'Customer Experience',
      path: '/engage/growth-loyalty/customer-experience',
      is_active: isActive('/engage/growth-loyalty/customer-experience'),
      is_visible: true
    }]
  }, {
    title: t('INTEL'),
    path: '/intel/revops-reporting',
    is_visible: isOwner || permissions?.can_access_ripe_intel,
    is_active: isActive('/intel/'),
    icon: intelIcon,
    children: [
    //TODO: display this when we have the data
    // {
    //   title: 'Ai Recommendations',
    //   path: '/intel/ai-recommendations',
    //   is_active: isActive('/intel/ai-recommendations'),
    //   is_visible: true,
    //   ai_enhanced: true
    // },
    {
      title: 'RevOps Reporting',
      path: '/intel/revops-reporting',
      is_active: isActive('/intel/revops-reporting'),
      is_visible: true
    }, {
      title: 'Product Performance',
      path: '/intel/product-performance',
      is_active: isActive('/intel/product-performance'),
      is_visible: true
    }, {
      title: 'Shopping Trends',
      path: '/intel/shopping-trends',
      is_active: isActive('/intel/shopping-trends'),
      is_visible: true
    }, {
      title: 'Customer Behaviors',
      path: '/intel/customer-behaviors',
      is_active: isActive('/intel/customer-behaviors'),
      is_visible: true
    }, {
      title: 'Communication Tracker',
      path: '/intel/communication-tracker',
      is_active: isActive('/intel/communication-tracker'),
      is_visible: true
    }]
  }] as Item[];
  const filteredItems = (items || []).filter((item): item is Item => Boolean(item?.is_visible)).map(item => ({
    ...item,
    children: item.children?.filter(child => child.is_visible) || []
  }));
  return [{
    title: t(''),
    items: filteredItems
  }];
};