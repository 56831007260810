import { AuthGuard } from '@/components/authentication/auth-guard';
import { MainLayout } from '@/layouts/main/main-layout';
import { MainSidebarContext } from '@/layouts/main/main-sidebar/main-sidebar-context';
import { useLoading } from '@/lib/contexts/loading-context';
import { useAuth } from '@/lib/hooks/use-auth';
import useClickTracker from '@/lib/hooks/use-click-tracker';
import NotificationHandler from '@/lib/services/notifications/notification-handler';
import { GetLayoutFn } from '@/pages/_app';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';

/**
 * Props for the PersistentLayout component
 * @property children - React nodes to be rendered within the layout
 * @property getLayout - Optional function to provide custom layout wrapping
 */
interface PersistentLayoutProps {
  children: ReactNode;
  getLayout?: GetLayoutFn;
}

/**
 * PersistentLayout is responsible for managing the application's layout structure
 * and authentication flow. It maintains a consistent UI across route changes while
 * handling different authentication states and route types.
 */
export function PersistentLayout({
  children,
  getLayout
}: PersistentLayoutProps) {
  // Manage sidebar state
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Get authentication state and router
  const {
    isAuthenticated
  } = useAuth();
  const router = useRouter();
  const {
    setLoading
  } = useLoading();

  /**
   * Determine if the current route is public/authentication related
   * This affects how we handle layout and authentication
   */
  const isPublicRoute = router.pathname.startsWith('/authentication/') || router.pathname === '/' || router.pathname === '/404' || router.pathname === '/401' || router.pathname === '/maintenance' || router.pathname === '/get-started' || router.pathname.startsWith('/onboarding/');
  useEffect(() => {
    // Update loading state when route changes
    setLoading(false);
  }, [router.pathname, setLoading]);

  // Configure click tracking
  const trackingOptions = {
    excludeSelectors: [
    // Privacy-sensitive elements
    'input[type="password"]', 'input[type="email"]', '.billing-info', '[data-private]',
    // MUI components that might create noise
    '.MuiTooltip-root', '.MuiPopover-root', '[role="tooltip"]'
    // Navigation elements
    //   '.main-sidebar a', // Avoid tracking sidebar navigation
    //   'nav button' // Avoid tracking nav buttons
    ],
    captureElements: true,
    slackWebhookUrl: process.env.NEXT_PUBLIC_SLACK_APP_USER_CLICK_TRACKING_WEBHOOK_URL,
    sampling: process.env.NODE_ENV === 'production' ? 1 : 1,
    // use 0.1 to Sample 10% in prod
    enableForPublicRoutes: false,
    // Only track authenticated routes
    batchSize: 5,
    // Send in batches of 5
    batchDelay: 2000
  };

  /**
   * PersistentWrapper component handles the consistent layout for authenticated routes
   * It maintains sidebar state and wraps content in the dashboard layout
   */
  const PersistentWrapper = ({
    children
  }: {
    children: ReactNode;
  }) => {
    // Initialize click tracking
    useClickTracker(trackingOptions);

    // Don't apply wrapper for public routes or unauthenticated users
    if (!isAuthenticated || isPublicRoute) return <>{children}</>;
    return <MainSidebarContext.Provider value={{
      isSidebarOpen,
      setSidebarOpen: setIsSidebarOpen
    }} data-sentry-element="unknown" data-sentry-component="PersistentWrapper" data-sentry-source-file="persistent-layout.tsx">
        <NotificationHandler data-sentry-element="NotificationHandler" data-sentry-source-file="persistent-layout.tsx" />
        <MainLayout data-sentry-element="MainLayout" data-sentry-source-file="persistent-layout.tsx">
          <div className="min-h-screen">
            <div className="page-transition-wrapper">{children}</div>
          </div>
        </MainLayout>
      </MainSidebarContext.Provider>;
  };

  /**
   * Handle custom layouts provided by individual pages
   * Some pages might need specific layout configurations
   */
  if (getLayout) {
    const layoutContent = getLayout(<>{children}</>);
    // Handle authenticated users accessing public routes
    if (isAuthenticated && isPublicRoute) {
      return <>{layoutContent}</>;
    }
    return layoutContent;
  }

  /**
   * Route-specific rendering logic
   */

  // Protected routes: Wrap with AuthGuard for unauthenticated users
  if (!isAuthenticated && !isPublicRoute) {
    return <AuthGuard>{children}</AuthGuard>;
  }

  // Public routes: Render directly without additional wrapping
  if (isPublicRoute) {
    return <>{children}</>;
  }

  // Default case: Wrap with persistent layout for authenticated routes
  return <PersistentWrapper data-sentry-element="PersistentWrapper" data-sentry-component="PersistentLayout" data-sentry-source-file="persistent-layout.tsx">{children}</PersistentWrapper>;
}