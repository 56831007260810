import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/hooks/use-auth';
import { useLoading } from '@/lib/contexts/loading-context';
interface AuthGuardProps {
  children: ReactNode;
}
export const AuthGuard: FC<React.PropsWithChildren<AuthGuardProps>> = props => {
  const {
    children
  } = props;
  const {
    isAuthenticated,
    isInitialized
  } = useAuth();
  const router = useRouter();
  const {
    setLoading
  } = useLoading();
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    // Don't check on public routes
    const isPublicRoute = router.pathname.startsWith('/authentication/') || router.pathname === '/';
    if (isPublicRoute) {
      setLoading(false);
      return;
    }

    // Only redirect if we're initialized and not authenticated
    if (!isAuthenticated && isInitialized) {
      router.push({
        pathname: '/authentication/login',
        query: {
          returnUrl: router.asPath
        }
      }).catch(error => {
        console.error('Navigation error:', error);
        setLoading(false); // Ensure loading is turned off even if navigation fails
      });
    }
  }, [router.isReady, router.pathname, isAuthenticated, isInitialized, setLoading]);

  // Only render children when authenticated
  if (!isAuthenticated) {
    return null;
  }
  return <>{children}</>;
};
AuthGuard.propTypes = {
  children: PropTypes.node
};