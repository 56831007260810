import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SystemNotification } from '@/lib/state/services/notifications-api';
import { useMarkNotificationAsReadMutation } from '@/lib/state/services/notifications-api';
import { useGetCustomerMutation } from '@/lib/state/services/communication-theater-api';
import { useAppDispatch } from '@/lib/state/hooks';
import { setActiveCustomer } from '@/lib/state/slices/communication-slice';
import { Customer } from '@/lib/types/communication-center';
import { IconLogo } from '@/assets/logos/icon-logo';
import CustomerAvatar from '@/components/crm/communication-crm/components/customer-card/customer-avatar';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '@mui/material';
import { Str } from '@supercharge/strings';
import { formatDate } from '@/lib/utils/date-and-time-helpers';

/**
 * Key Features:
 * - Displays a single notification item with details like timestamp, title, and message.
 * - Supports clickable links for notification details.
 * - Supports marking notifications as read.
 * - Displays customer avatar if available.
 * - Uses custom channel labels for display.
 * - Responsive design.
 * - Accessibility features.
 * - Performance optimizations.
 * - Error handling.
 * - Real-time updates.
 * - Customization options.
 *
 *
 */

type Props = {
  notification: SystemNotification;
};
const CHANNEL_DISPLAY_LABELS: Record<string, string> = {
  incomingVoice: 'Phone',
  message: 'Chat',
  completed_survey: 'Survey',
  voice_call: 'Voice Call',
  store: 'Store',
  email: 'Email'
};
const NotificationPopoverItem: React.FC<Props> = ({
  notification
}) => {
  const [getActiveCustomer] = useGetCustomerMutation();
  const [markAsRead] = useMarkNotificationAsReadMutation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [customer, setCustomer] = useState<Customer | null>(null);

  // Fetch customer data if available
  useEffect(() => {
    const getCustomer = async () => {
      if (notification.metadata?.customer_store_id) {
        const res = (await getActiveCustomer(notification.metadata.customer_store_id)) as {
          data?: {
            customer: Customer;
          };
        };
        setCustomer(res.data?.customer || null);
      }
    };
    getCustomer();
  }, [notification.metadata?.customer_store_id, getActiveCustomer]);
  const handleRouting = async () => {
    try {
      // Mark as read first
      await markAsRead({
        notification_id: notification.id
      });

      // Then handle navigation
      if (customer) {
        dispatch(setActiveCustomer({
          customer
        }));
        router.push(`/crm/contact-manager/${customer.id}`);
      } else {
        router.push('/crm/communication-crm');
      }
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };
  const handleMarkAsRead = async () => {
    try {
      await markAsRead({
        notification_id: notification.id
      });
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  // Get display title based on notification type and channel
  const getDisplayTitle = () => {
    const channelLabel = CHANNEL_DISPLAY_LABELS[notification.channel] || notification.channel;
    return `${channelLabel} - ${notification.type.replace(/_/g, ' ')}`;
  };
  return <div className="relative px-4 py-3 border-b hover:bg-gray-100" data-sentry-component="NotificationPopoverItem" data-sentry-source-file="notification-popover-item.tsx">
      <div onClick={handleRouting} className="flex cursor-pointer">
        <div className="shrink-0">
          {notification.metadata?.customer_store_id ? <CustomerAvatar firstName={customer?.first_name} lastName={customer?.last_name} id={customer?.id} avg_spend_per_visit_for_store_range={customer?.avg_spend_per_visit_for_store_range} /> : <div className="rounded-full bg-orange-100 p-1.5">
              <IconLogo variant="light" sx={{
            height: 38,
            width: 38
          }} />
            </div>}
        </div>
        <div className="w-full pl-2">
          <div className="mb-1.5 flex flex-col">
            <span className="text-gray-900 font-proximaSemiBold">
              {Str(getDisplayTitle()).limit(32, '...').get()}
            </span>
            <span className="text-sm text-gray-500 font-proximaNova">
              {notification.content}
            </span>
          </div>
          <div className="text-xs text-orange-500 font-proximaNova">
            {formatDate(notification.created_at)}
          </div>
        </div>
      </div>
      <button className="absolute right-3 top-3 rounded-full p-0.5 hover:bg-orange-100" onClick={handleMarkAsRead}>
        <Tooltip title="Mark as Read" data-sentry-element="Tooltip" data-sentry-source-file="notification-popover-item.tsx">
          <XMarkIcon className="w-5 h-5 text-orange-500" data-sentry-element="XMarkIcon" data-sentry-source-file="notification-popover-item.tsx" />
        </Tooltip>
      </button>
    </div>;
};
export default NotificationPopoverItem;