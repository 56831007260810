import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem } from '@mui/material';
import type { ListItemProps } from '@mui/material';
import { ChevronDown } from '@/assets/icons/chevron-down';
import { ChevronUp } from '@/assets/icons/chevron-up';
import { MainSidebarAiBadge } from '@/layouts/main/main-sidebar/main-sidebar-ai-badge';
interface MainSidebarItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  chip?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  is_category?: boolean;
  ai_enhanced?: boolean;
}
export const MainSidebarItem: FC<React.PropsWithChildren<MainSidebarItemProps>> = props => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    is_category,
    ai_enhanced,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(!!openProp);
  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };
  let paddingLeft = 18;
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return <ListItem disableGutters sx={{
      display: 'block',
      mb: 0.5,
      py: 0,
      px: 1
    }} {...other}>
        <Button endIcon={!open ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />} disableRipple onClick={handleToggle} startIcon={icon} sx={{
        fontWeight: 500,
        color: active ? 'neutral.100' : 'neutral.300',
        justifyContent: 'flex-start',
        pl: `${paddingLeft}px`,
        pr: 3,
        textAlign: 'left',
        textTransform: 'none',
        backgroundColor: active ? 'primary.main' : 'transparent',
        width: '100%',
        '&:hover': {
          backgroundColor: active ? 'rgba(255,255,255, 0.08)' : 'primary.main',
          color: '#ffffff'
        },
        '& .MuiButton-startIcon': {
          color: '#ffffff'
        },
        '& .MuiButton-endIcon': {
          color: '#ffffff'
        }
      }}>
          <div className="flex items-center text-base grow">
            <span className="text-base text-white font-proximaExtraBold">
              Ripe
            </span>
            <span className="text-base font-thin">{title}</span>
          </div>
          {info}
        </Button>

        <Collapse in={open} sx={{
        mt: 0.5
      }}>
          {children}
        </Collapse>
      </ListItem>;
  }

  // Category item
  if (is_category) {
    return <ListItem disableGutters sx={{
      display: 'block',
      mb: 0.5,
      py: 0,
      px: 0
    }}>
        <div className="flex items-center py-1 text-xs text-white cursor-default font-proximaBold" style={{
        paddingLeft: `${paddingLeft}px`
      }}>
          {title}
        </div>
      </ListItem>;
  }

  // Leaf
  return <ListItem disableGutters sx={{
    display: 'block',
    mb: 0.5,
    py: 0,
    px: 0
  }} data-sentry-element="ListItem" data-sentry-component="MainSidebarItem" data-sentry-source-file="main-sidebar-item.tsx">
      <NextLink href={path as string} passHref data-sentry-element="NextLink" data-sentry-source-file="main-sidebar-item.tsx">
        <Button startIcon={icon} endIcon={chip} disableRipple sx={{
        fontWeight: 500,
        fontFamily: active ? 'proxima-nova-bold' : 'proxima-nova-regular',
        borderRadius: 1,
        color: 'neutral.300',
        justifyContent: 'flex-start',
        pl: `${paddingLeft}px`,
        pr: 3,
        textAlign: 'left',
        textTransform: 'none',
        width: '100%',
        ...(active && {
          color: 'primary.main'
        }),
        '& .MuiButton-startIcon': {
          color: active ? 'primary.main' : '#ffffff'
        },
        '&:hover': {
          backgroundColor: 'rgba(255,255,255, 0.08)'
        }
      }} data-sentry-element="Button" data-sentry-source-file="main-sidebar-item.tsx">
          <div className="flex items-center justify-between gap-4 text-xs grow">
            <span>{title}</span>
            {ai_enhanced && <MainSidebarAiBadge />}
          </div>
          {info}
        </Button>
      </NextLink>
    </ListItem>;
};
MainSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  is_category: PropTypes.bool,
  ai_enhanced: PropTypes.bool
};
MainSidebarItem.defaultProps = {
  active: false,
  open: false,
  is_category: false,
  ai_enhanced: false
};