import { IncomingCallDataResponse } from '@/lib/types/rtk-types/call-center';
import { api } from './api';

export const callCenterApi = api.injectEndpoints({
  endpoints: (build) => ({
    // ID is database record ID
    claimCall: build.mutation<IncomingCallDataResponse, number>({
      query: (id) => {
        return {
          url: `v2/claim_call/${id}`,
          method: 'GET'
        };
      },
      invalidatesTags: ['CommunicationCrmData']
    }),
    hangUpCall: build.mutation<unknown, number>({
      query: (id) => {
        return {
          url: `v2/hangup_call/${id}`,
          method: 'GET'
        };
      },
      invalidatesTags: ['CommunicationCrmData']
    }),
    callCustomer: build.mutation<IncomingCallDataResponse, number>({
      query: (id) => {
        return {
          url: `v2/dial_customer/${id}`,
          method: 'GET'
        };
      },
      invalidatesTags: ['CommunicationCrmData']
    }),
    holdCall: build.mutation<IncomingCallDataResponse, number>({
      query: (id) => {
        return {
          url: `v2/hold_call/${id}`,
          method: 'GET'
        };
      },
      invalidatesTags: ['CommunicationCrmData']
    }),
    muteCall: build.mutation<IncomingCallDataResponse, number>({
      query: (id) => {
        return {
          url: `v2/mute_call/${id}`,
          method: 'GET'
        };
      },
      invalidatesTags: ['CommunicationCrmData']
    })
  }),
  overrideExisting: true
});

export const {
  useClaimCallMutation,
  useHangUpCallMutation,
  useCallCustomerMutation,
  useHoldCallMutation,
  useMuteCallMutation
} = callCenterApi;
