import {
  SupportedTableNames,
  TableSelectionState
} from '@/lib/types/rtk-types/table-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TableSelectionState = {
  contactManager: {
    selectedIds: [],
    selectAll: false
  },
  communicationVolume: {
    selectedIds: [],
    selectAll: false
  },
  prospects: {
    selectedIds: [],
    selectAll: false
  },
  automatedSegmentContacts: {
    selectedIds: [],
    selectAll: false
  },
  productPerformance: {
    selectedIds: [],
    selectAll: false
  },
  initiatedLandingPages: {
    selectedIds: [],
    selectAll: false
  },
  initializedCampaigns: {
    selectedIds: [],
    selectAll: false
  },
  featuredDeals: {
    selectedIds: [],
    selectAll: false
  },
  narratives: {
    selectedIds: [],
    selectAll: false
  },
  reconsiderProducts: {
    selectedIds: [],
    selectAll: false
  }
};
export const tableSelectionSlice = createSlice({
  name: 'tableSelection',
  initialState,
  reducers: {
    toggleRowSelection: (
      state,
      action: PayloadAction<{
        id: number;
        selected: boolean;
        table: SupportedTableNames;
      }>
    ) => {
      const { id, selected, table } = action.payload;
      const { selectedIds } = state[table];

      if (selected && !selectedIds.includes(id)) {
        selectedIds.push(id);
      } else if (!selected) {
        const index = selectedIds.indexOf(id);
        if (index > -1) {
          selectedIds.splice(index, 1);
        }
        state[table].selectAll = false;
      }
    },
    toggleSelectAll: (
      state,
      action: PayloadAction<{
        selected: boolean;
        table: SupportedTableNames;
      }>
    ) => {
      const { table, selected } = action.payload;
      // Clear existing selections
      state[table].selectedIds = [];
      state[table].selectAll = selected;
    }
  }
});

export const { toggleRowSelection, toggleSelectAll } =
  tableSelectionSlice.actions;

export default tableSelectionSlice.reducer;
