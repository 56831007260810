import type { Theme } from '@mui/material';
import {
  createTheme as createMuiTheme,
  responsiveFontSizes
} from '@mui/material/styles';
import { useMemo } from 'react';
import { baseThemeOptions } from './base-theme-options';
import { darkThemeOptions } from './dark-theme-options';
import { lightThemeOptions } from './light-theme-options';

interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    // @ts-ignore
    neutral?: Neutral;
  }

  interface PaletteOptions {
    neutral?: Neutral;
  }
}

interface ThemeConfig {
  responsiveFontSizes?: boolean;
  mode: 'light' | 'dark';
}

// Keep your existing createTheme for non-React contexts
export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    baseThemeOptions,
    config.mode === 'dark' ? darkThemeOptions : lightThemeOptions
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

// Add this new hook for React components
export const useTheme = (config: ThemeConfig): Theme => {
  return useMemo(
    () => createTheme(config),
    [config.mode, config.responsiveFontSizes]
  );
};
