import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@/lib/state/hooks';
import { Device } from '@twilio/voice-sdk';
import { updateIsCallActive } from '@/lib/state/slices/call-center-slice';
import IncomingOutgoingActiveCallComponent from '@/components/_shared/call-center/call-center-new-components/incoming-outgoing-active-call-components';

/* Description:
 * This component is used to manage the call center user bar in the dashboard.
 * It takes a Twilio Device object as a prop and renders the UnifiedCallComponent.
 * The UnifiedCallComponent is used to manage incoming and outgoing calls.
 * The component also updates the Redux store with call status and call data.
 */

type Props = {
  twilioDevice: Device | null;
};
const MainAppBarCallCenterComponent: React.FC<Props> = ({
  twilioDevice
}) => {
  const dispatch = useAppDispatch();

  // Redux state
  const isOutboundCall = useAppSelector(state => state.callCenter.isOutboundCall);
  const outboundCallStatus = useAppSelector(state => state.callCenter.outboundCallStatus);

  // Local state
  const [shouldDisplayCallWidget, setShouldDisplayCallWidget] = useState<boolean>(false);
  useEffect(() => {
    // Slight delay to prevent flickering on initial render
    const timer = setTimeout(() => {
      setShouldDisplayCallWidget(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (isOutboundCall && (outboundCallStatus === 'connected' || outboundCallStatus === 'ringing')) {
      dispatch(updateIsCallActive(true));
    }
  }, [isOutboundCall, outboundCallStatus, dispatch]);

  // Only render the IncomingOutgoingActiveCallComponent when we should display the call widget
  return <div className="flex space-x-4" data-sentry-component="MainAppBarCallCenterComponent" data-sentry-source-file="main-app-bar-call-center-component.tsx">
      {shouldDisplayCallWidget && <IncomingOutgoingActiveCallComponent twilioDevice={twilioDevice} />}
    </div>;
};
export default MainAppBarCallCenterComponent;