// This file edits the Navbar Menu Section Titles - Currently Unused - Ignore for Now
//
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { ListProps } from '@mui/material';
import { List, ListSubheader } from '@mui/material';
import { MainSidebarItem } from '@/layouts/main/main-sidebar/main-sidebar-item';
interface Item {
  path?: string;
  icon?: ReactNode;
  chip?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  is_category?: boolean;
  ai_enhanced?: boolean;
}
interface MainSidebarSectionProps extends ListProps {
  items: Item[];
  path: string;
  title: string;
}
//possible to add a badge to the sidebar item
const renderNavItems = ({
  depth = 0,
  items,
  path
}: {
  depth?: number;
  items: Item[];
  path: string;
}): JSX.Element => <List disablePadding data-sentry-element="List" data-sentry-component="renderNavItems" data-sentry-source-file="main-sidebar-section.tsx">
    {items.reduce((acc: JSX.Element[], item) => reduceChildRoutes({
    acc,
    depth,
    item,
    path
  }), [])}
  </List>;
const reduceChildRoutes = ({
  acc,
  depth,
  item,
  path
}: {
  acc: JSX.Element[];
  depth: number;
  item: Item;
  path: string;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;

  // Enhanced logic for determining active state
  const isCustomerSegmentsRoute = (routePath: string): boolean => {
    return routePath.includes('/engage/marketing-workflow/customer-segments');
  };
  const partialMatch = item.path ?
  // Special handling for Customer Segments section
  isCustomerSegmentsRoute(item.path) ? isCustomerSegmentsRoute(path) :
  // If it has children, check if we're in that section
  item.children ? path.startsWith(item.path.split('/').slice(0, 2).join('/')) :
  // If no children, check if current path starts with item path
  path.startsWith(item.path) : false;
  if (item.children) {
    acc.push(<MainSidebarItem active={partialMatch} chip={item.chip} depth={depth} icon={item.icon} info={item.info} key={key} open={partialMatch} path={item.path} title={item.title} is_category={item.is_category} ai_enhanced={item.ai_enhanced}>
        {renderNavItems({
        depth: depth + 1,
        items: item.children,
        path
      })}
      </MainSidebarItem>);
  } else {
    acc.push(<MainSidebarItem active={partialMatch} chip={item.chip} depth={depth} icon={item.icon} info={item.info} key={key} path={item.path} title={item.title} is_category={item.is_category} ai_enhanced={item.ai_enhanced} />);
  }
  return acc;
};
export const MainSidebarSection: FC<React.PropsWithChildren<MainSidebarSectionProps>> = props => {
  const {
    items,
    path,
    title,
    ...other
  } = props;
  return <>
      <List subheader={<ListSubheader disableGutters disableSticky sx={{
      color: 'neutral.500',
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: 2.5,
      ml: 4
    }}>
            {title}
          </ListSubheader>} {...other} data-sentry-element="List" data-sentry-source-file="main-sidebar-section.tsx">
        {renderNavItems({
        items,
        path
      })}
      </List>
    </>;
};
MainSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};