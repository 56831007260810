import { UserInterface, UserBillingInfo } from '@/lib/types/user-interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

interface AuthState {
  user: UserInterface | null;
}

interface ActiveAssignmentsPayload {
  active_assignments: Assignment[];
}

export interface Assignment {
  action_taken?: string;
  assignable_id?: number;
  assignable_type?: string;
  assigner_id?: number;
  created_at: Dayjs;
  customer_name?: string;
  customer_store: {
    id: number;
    name: string;
  };
  customer_store_id: number;
  employee_id?: number;
  id: number;
  status?: string;
  store_id: number;
  updated_at: Dayjs;
  urgency?: string;
  user_id?: number;
  user?: {
    id: number;
    name: string;
  };
}

const initialState: AuthState = { user: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state: AuthState,
      action: PayloadAction<{ user: UserInterface }>
    ) => {
      console.log('Setting user credentials:', action.payload);
      const { user } = action.payload;
      state.user = user;
    },
    logOut: (state: AuthState) => {
      console.log('Logging out user, clearing state');
      state.user = null;
    },
    invalidateUserBilling: (state: AuthState) => {
      console.log('Invalidating user billing in Redux...', {
        currentBilling: state.user?.billing
      });

      if (state.user?.billing) {
        const updatedBilling = {
          ...state.user.billing,
          price: null,
          servicePlan: null,
          stripe_id: null,
          has_payment_method: false,
          is_free_tier: true,
          // Preserve address and user_count
          address: state.user.billing.address,
          user_count: state.user.billing.user_count
        };

        console.log('Updated billing state:', updatedBilling);

        state.user = {
          ...state.user,
          billing: updatedBilling
        };
      }
    },
    updateUserBilling: (
      state: AuthState,
      action: PayloadAction<Partial<UserBillingInfo>>
    ) => {
      console.log('Updating user billing in Redux...', {
        currentBilling: state.user?.billing,
        newData: action.payload
      });

      if (state.user?.billing) {
        const updatedBilling = {
          ...state.user.billing,
          ...action.payload
        };

        console.log('New billing state:', updatedBilling);

        state.user = {
          ...state.user,
          billing: updatedBilling
        };
      }
    }
  }
});

export const {
  setCredentials,
  logOut,
  invalidateUserBilling,
  updateUserBilling
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: AuthState) => state.user;
