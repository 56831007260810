import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from './services/api';

// Core feature reducers
import authReducer from '@/lib/state/slices/auth-slice';
import userPermissionsReducer from '@/lib/state/slices/user-permissions-slice';
import calendarReducer from '@/lib/state/slices/calendar-slice';
import notificationsReducer from '@/lib/state/slices/calendar-notification-slice';

// Communication related reducers
import communicationReducer from '@/lib/state/slices/communication-slice';
import callCenterReducer from '@/lib/state/slices/call-center-slice';

// Marketing related reducers
import marketingSourcesReducer from '@/lib/state/slices/marketing-source-slice';

// UI state reducers
import timeframeReducer from '@/lib/state/slices/timeframe-slice';
import campaignsSelectorReducer from '@/lib/state/slices/campaigns-selector-slice';
import shoppingTrendsSplineReducer from '@/lib/state/slices/shopping-trends-spline-selector-slice';
import displayMessagingDashboardContainerReducer from '@/lib/state/slices/display-messaging-dashboard-container-slice';

// Filter reducers
import filterCustomerFeedbackReducer from '@/lib/state/slices/filter-customer-feedback-slice';
import filterCommunicationCrmContactListReducer from '@/lib/state/slices/filter-communication-theater-contact-list-slice';
import filterTimelineItemsReducer from '@/lib/state/slices/filter-timeline-items-slice';

// Selection reducers
import selectProductPerformanceAndReconsiderIDsReducer from '@/lib/state/slices/select-product-performance-and-reconsider-ids-slice';
import selectMarketingAppraisalSourceIDReducer from '@/lib/state/slices/select-marketing-appraisal-source-slice';
import selectMultiLocationStoreIdSlice from '@/lib/state/slices/select-multi-location-store-id-slice';
import contactProfileSidebarReducer from '@/lib/state/slices/contact-profile-sidebar-slice';
import selectMultiOperationAnalysisIdsAndObjectsReducer from '@/lib/state/slices/select-multi-operation-analysis-ids-and-objects-slice';
import multiOperationsLocationTableRadioSelectReducer from '@/lib/state/slices/multi-operations-location-table-radio-select-slice';
import selectContactsIdReducer from '@/lib/state/slices/select-prospects-id-slice';
import selectCommunicationVolumeSplineDataReducer from '@/lib/state/slices/select-communication-volume-spline-data-slice';
import tableSelectionReducer from '@/lib/state/slices/table-selection-slice';

// Tab reducers - organized by feature area
import settingsAiTrainingManualTabsReducer from '@/lib/state/slices/page-tabs-views/settings-ai-training-manual-tabs-slice';
import profileTabsReducer from '@/lib/state/slices/page-tabs-views/settings-profile-tabs-slice';
import settingsAiChatbotTabsReducer from '@/lib/state/slices/page-tabs-views/settings-ai-chatbot-tabs-slice';
import settingsAiTrainingTabsReducer from '@/lib/state/slices/page-tabs-views/settings-ai-training-tabs-slice';
import settingsAiSetupTabsReducer from '@/lib/state/slices/page-tabs-views/settings-ai-setup-tabs-slice';
import completedOnboardingTabsReducer from '@/lib/state/slices/page-tabs-views/completed-onboarding-tabs-slice';
import settingsTabReducer from '@/lib/state/slices/page-tabs-views/settings-tabs-slice';
import settingsIntegrationsTabsReducer from '@/lib/state/slices/page-tabs-views/settings-integrations-tabs-slice';
// Form reducers
import productFormReducer from '@/lib/state/slices/product-form-slice';
import prospectFormReducer from '@/lib/state/slices/prospect-form-slice';

// AI-related reducers
import aiTrainingTextInputsReducer from '@/lib/state/slices/ai-training-text-inputs-slice';
import aiChatbotPersonalityInputsReducer from '@/lib/state/slices/ai-chatbot-personality-inputs-slice';

// Misc reducers
import planIdReducer from '@/lib/state/slices/plan-id-slice';
import pricingReducer from '@/lib/state/slices/pricing';
import counterReducer from '@/lib/state/slices/notification-bell-slice';
import prospectManagementTabsReducer from '@/lib/state/slices/page-tabs-views/prospect-management-tabs-slice';

// Types
import type { AnyAction } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';

// Configure Redux Persist
const persistConfig = {
  key: 'root',
  storage:
    typeof window !== 'undefined'
      ? storage
      : require('redux-persist/lib/storage').default,
  blacklist: ['api', 'auth', 'callCenter'] // These reducers won't be persisted
};

// Organize reducers by feature
const rootReducer = combineReducers({
  // API
  [api.reducerPath]: api.reducer,

  // Core features
  auth: authReducer,
  userPermissions: userPermissionsReducer,
  calendar: calendarReducer,
  notifications: notificationsReducer,

  // Communication features
  communication: communicationReducer,
  callCenter: callCenterReducer,

  // Marketing features
  marketingSources: marketingSourcesReducer,

  // Filters
  filterCustomerFeedback: filterCustomerFeedbackReducer,
  filterTimelineItems: filterTimelineItemsReducer,
  filterCommunicationCrmContactList: filterCommunicationCrmContactListReducer,

  // UI State
  timeframe: timeframeReducer,
  campaignsSelector: campaignsSelectorReducer,
  shoppingTrendsSplineSelector: shoppingTrendsSplineReducer,
  displayMessagingDashboardContainer: displayMessagingDashboardContainerReducer,

  // Selectors
  selectProductPerformanceAndReconsiderIDs:
    selectProductPerformanceAndReconsiderIDsReducer,
  selectMarketingAppraisalSourceID: selectMarketingAppraisalSourceIDReducer,
  selectMultiLocationStoreId: selectMultiLocationStoreIdSlice,
  contactProfileSidebar: contactProfileSidebarReducer,
  selectMultiOperationAnalysisIdsAndObjects:
    selectMultiOperationAnalysisIdsAndObjectsReducer,
  multiOperationsLocationTableRadioSelect:
    multiOperationsLocationTableRadioSelectReducer,
  prospectsIdSelect: selectContactsIdReducer,
  selectCommunicationVolumeSplineData:
    selectCommunicationVolumeSplineDataReducer,
  tableSelection: tableSelectionReducer,

  // Tab states
  settingsTab: settingsTabReducer,
  settingsAiTrainingManualTab: settingsAiTrainingManualTabsReducer,
  profileTabs: profileTabsReducer,
  settingsAiSetupTabs: settingsAiSetupTabsReducer,
  settingsAiChatbotTabs: settingsAiChatbotTabsReducer,
  settingsAiTrainingTabs: settingsAiTrainingTabsReducer,
  settingsIntegrationsTabs: settingsIntegrationsTabsReducer,
  completedOnboardingTabs: completedOnboardingTabsReducer,

  // Forms
  productForm: productFormReducer,
  prospectForm: prospectFormReducer,

  // AI Features
  aiTrainingTextInputs: aiTrainingTextInputsReducer,
  aiChatbotPersonalityInputs: aiChatbotPersonalityInputsReducer,

  // Misc
  counter: counterReducer,
  planId: planIdReducer,
  pricing: pricingReducer,

  prospectManagementTabs: prospectManagementTabsReducer
});

// Create store with Next.js SSR and Redux Persist support
function makeStore() {
  const isServer = typeof window === 'undefined';

  if (isServer) {
    // Server-side store configuration
    return configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
          }
        }).concat(api.middleware)
    });
  }

  // Client-side store configuration
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat(api.middleware)
  });

  return store;
}

export const store = makeStore();
export const persistor = persistStore(store);
// Type exports
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
