/**
 * @file NotificationsPopover.tsx
 * @description A popover component that displays a list of notifications with scroll functionality.
 * Serves as the container for notification items and manages the notification list display.
 *
 * Component Architecture:
 * - Functional component with TypeScript props
 * - Material-UI Popover as base container
 * - Custom scrollbar implementation
 * - Conditional rendering based on notification state
 * - Responsive design with fixed width
 *
 * Visual Structure:
 * ┌─────────────────────────────┐
 * │ Notifications (Header)      │
 * ├─────────────────────────────┤
 * │ ┌─────────────────────────┐ │
 * │ │ Notification Item 1     │ │
 * │ ├─────────────────────────┤ │
 * │ │ Notification Item 2     │ │
 * │ ├─────────────────────────┤ │
 * │ │ Notification Item 3     │ │
 * │ └─────────────────────────┘ │
 * ├─────────────────────────────┤
 * │ View All Notifications      │
 * └─────────────────────────────┘
 *
 * @example
 * ```tsx
 * <NotificationsPopover
 *   anchorEl={buttonRef.current}
 *   notifications={notificationsList}
 *   onClose={handleClose}
 *   onViewAll={handleViewAll}
 *   open={isOpen}
 * />
 * ```
 */

import { Scrollbar } from '@/components/_shared/scrollbar';
import NotificationPopoverEmptyState from '@/layouts/main/main-app-bar/notifications-popover-button/notification-popover-empty-state';
import NotificationPopoverHeader from '@/layouts/main/main-app-bar/notifications-popover-button/notification-popover-header';
import NotificationPopoverItem from '@/layouts/main/main-app-bar/notifications-popover-button/notification-popover-item';
import { SystemNotification, useMarkAllNotificationsAsReadMutation } from '@/lib/state/services/notifications-api';
import { Box, Popover } from '@mui/material';
import NextLink from 'next/link';
import { FC } from 'react';

/**
 * Props interface for the NotificationsPopover component
 * @interface NotificationsPopoverProps
 */
interface NotificationsPopoverProps {
  /** Element that triggers the popover */
  anchorEl: null | Element;

  /** Array of notification objects to display */
  notifications: SystemNotification[];

  /** Optional callback for when the popover closes */
  onClose?: () => void;

  /** Controls popover visibility */
  open?: boolean;
}

/**
 * NotificationsPopover Component
 *
 * Displays a popover containing a list of notifications with custom styling and scroll behavior.
 * Handles empty states and provides navigation to full notification view.
 *
 * @component
 * @param props - Component props (see NotificationsPopoverProps interface)
 *
 * Features:
 * - Custom scrollbar for notification list
 * - Empty state handling
 * - Fixed width design (380px)
 * - Responsive layout
 * - Material-UI integration
 *
 * Styling:
 * - Uses Tailwind CSS for layout and spacing
 * - Material-UI components for structure
 * - Custom scrollbar styling
 * - Hover states for interactive elements
 *
 * @returns {JSX.Element} Rendered component
 */
export const NotificationsPopover: FC<NotificationsPopoverProps> = ({
  anchorEl,
  notifications,
  onClose,
  open = false
}) => {
  const [markAllAsRead] = useMarkAllNotificationsAsReadMutation();

  // Handler for marking all notifications as read
  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead();
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  };
  return <Popover anchorEl={anchorEl} anchorOrigin={{
    horizontal: 'left',
    vertical: 'bottom'
  }} onClose={onClose} open={open} slotProps={{
    paper: {
      sx: {
        width: 380
      } // Fixed width for consistent layout
    }
  }}
  // Accessibility attributes
  aria-label="Notifications popover" role="dialog" data-sentry-element="Popover" data-sentry-component="NotificationsPopover" data-sentry-source-file="notifications-popover.tsx">
      {/* Header Section */}
      <NotificationPopoverHeader handleMarkAllAsRead={handleMarkAllAsRead} notifications={notifications} data-sentry-element="NotificationPopoverHeader" data-sentry-source-file="notifications-popover.tsx" />

      {/* Content Section */}
      {notifications.length === 0 ?
    // Empty State
    <NotificationPopoverEmptyState /> :
    // Notification List with Scroll
    <Scrollbar sx={{
      maxHeight: 400
    }}
    // Accessibility for keyboard navigation
    role="list" tabIndex={0}>
          <ul className="divide-y divide-gray-100">
            {notifications.map(notification => <NotificationPopoverItem key={notification.id} notification={notification} />)}
          </ul>
        </Scrollbar>}
      {/* Footer Section */}
      <Box className="p-2 border-t border-gray-100" data-sentry-element="Box" data-sentry-source-file="notifications-popover.tsx">
        <NextLink href="/notification-history" passHref onClick={onClose} data-sentry-element="NextLink" data-sentry-source-file="notifications-popover.tsx">
          <button className="w-full px-3 py-2 text-sm text-center text-gray-700 rounded-md hover:bg-orange-50 hover:text-orange-500" aria-label="View all notifications">
            View All Notifications
          </button>
        </NextLink>
      </Box>
    </Popover>;
};