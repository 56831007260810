// lib/contexts/loading-context.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '@/lib/hooks/use-auth';
import { usePrioritizedServices } from '@/lib/hooks/use-service-loader';
import { SplashScreen } from '@/components/_shared/splash-screen';
interface LoadingContextType {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
}
const LoadingContext = createContext<LoadingContextType>({
  isLoading: true,
  setLoading: () => undefined
});
export const useLoading = () => useContext(LoadingContext);
interface LoadingProviderProps {
  children: React.ReactNode;
}
export const LoadingProvider: React.FC<LoadingProviderProps> = ({
  children
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    isInitialized,
    isAuthenticated
  } = useAuth();
  const services = usePrioritizedServices();
  useEffect(() => {
    const timer = setTimeout(() => {
      const shouldLoad = !isInitialized || !services.criticalReady;
      setIsLoading(shouldLoad);
    }, 100); // Small delay to prevent flashing

    return () => clearTimeout(timer);
  }, [isInitialized, isAuthenticated, services.criticalReady]);
  return <LoadingContext.Provider value={{
    isLoading,
    setLoading: setIsLoading
  }} data-sentry-element="unknown" data-sentry-component="LoadingProvider" data-sentry-source-file="loading-context.tsx">
      {isLoading ? <SplashScreen /> : children}
    </LoadingContext.Provider>;
};