import { Menu as MenuIcon } from '@/assets/icons/menu';
import MainAppBarCallCenterComponent from '@/components/_shared/call-center/main-app-bar-call-center-component';
import { Skeleton } from '@/components/ui/skeleton';
import CalendarNotificationCard from '@/layouts/main/main-app-bar/calendar-notification';
import IvrCount from '@/layouts/main/main-app-bar/ivr-count';
import { NotificationsPopoverButton } from '@/layouts/main/main-app-bar/notifications-popover-button/notifications-popover-button';
import { UserButton } from '@/layouts/main/main-app-bar/user-button/user-button';
import useTwilioDevice from '@/lib/hooks/use-twilio-device';
import { useAppSelector } from '@/lib/state/hooks';
import { useGetStoreSettingsQuery } from '@/lib/state/services/customer-settings-api';
import type { AppBarProps } from '@mui/material';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import type { FC } from 'react';
interface MainAppBarProps extends AppBarProps {
  onOpenSidebar?: () => void;
}
const MainAppBarRoot = styled(AppBar)(({
  theme
}) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light' ? {
    boxShadow: theme.shadows[3]
  } : {
    backgroundColor: theme.palette.background.paper,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    boxShadow: 'none'
  })
}));
const DisplayStoreName = () => {
  const {
    data: storeData,
    isLoading,
    isFetching
  } = useGetStoreSettingsQuery();
  const showSkeleton = isLoading || isFetching || !storeData?.data;
  const storeName = storeData?.data?.name ?? '';
  return <Box sx={{
    alignItems: 'center',
    display: 'flex',
    ml: 1
  }} data-sentry-element="Box" data-sentry-component="DisplayStoreName" data-sentry-source-file="main-app-bar.tsx">
      {showSkeleton ? <Skeleton className="w-16 h-2" /> : <Typography color="textPrimary" variant="subtitle2">
          {storeName}
        </Typography>}
    </Box>;
};

/* Description: This component is the userbar that appears at the top of the dashboard layout. It contains the sidebar toggle button, call center component, calendar notification card, IVR notification counter, store name, notifications button, and user button. */

export const MainAppBar: FC<React.PropsWithChildren<MainAppBarProps>> = props => {
  const {
    onOpenSidebar,
    ...other
  } = props;
  const isCallCenterActive = useAppSelector(state => state.callCenter.widgetVisibility);
  const token = useAppSelector(state => state.callCenter.twilioToken);
  const twilioDevice = useTwilioDevice(token);

  //get ivt current calls counter from redux
  const ivrCurrentCallsCounter: number = useAppSelector(state => state.callCenter.ivrCurrentCallsCounter);
  return <>
      <MainAppBarRoot {...other} data-sentry-element="MainAppBarRoot" data-sentry-source-file="main-app-bar.tsx">
        {/* Userbar content */}
        <Toolbar disableGutters sx={{
        minHeight: 64,
        left: 0,
        px: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }} data-sentry-element="Toolbar" data-sentry-source-file="main-app-bar.tsx">
          {/* Sidebar toggle button */}
          <IconButton onClick={onOpenSidebar} sx={{
          display: {
            xs: 'inline-flex',
            xl: 'inline-flex'
          }
        }} data-sentry-element="IconButton" data-sentry-source-file="main-app-bar.tsx">
            <MenuIcon fontSize="small" data-sentry-element="MenuIcon" data-sentry-source-file="main-app-bar.tsx" />
          </IconButton>

          {/* CALL CENTER - Phone Component */}
          {isCallCenterActive && <MainAppBarCallCenterComponent twilioDevice={twilioDevice} />}
          <CalendarNotificationCard data-sentry-element="CalendarNotificationCard" data-sentry-source-file="main-app-bar.tsx" />

          <Box sx={{
          display: 'flex'
        }} data-sentry-element="Box" data-sentry-source-file="main-app-bar.tsx">
            {/* <ColorModeButton /> */}
            {/* <LanguageButton /> */}
            {/* <ContentSearchButton /> */}
            {/* <MessagesButton /> */}

            {/* IVR NOTIFICATION COUNTER - POP UP DIALOG */}
            {ivrCurrentCallsCounter > 0 && <IvrCount ivrCurrentCallsCounter={ivrCurrentCallsCounter} />}

            <DisplayStoreName data-sentry-element="DisplayStoreName" data-sentry-source-file="main-app-bar.tsx" />
            <NotificationsPopoverButton data-sentry-element="NotificationsPopoverButton" data-sentry-source-file="main-app-bar.tsx" />
            <UserButton data-sentry-element="UserButton" data-sentry-source-file="main-app-bar.tsx" />
          </Box>
        </Toolbar>
      </MainAppBarRoot>
    </>;
};
MainAppBar.propTypes = {
  onOpenSidebar: PropTypes.func
};